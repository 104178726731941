import { createStore } from "vuex";
import { PublicClientApplication } from "@azure/msal-browser";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    sportNames: {
      1: "archery",
      2: "badminton",
      3: "baseball",
      4: "basketball",
      5: "bouldering",
      6: "boxing",
      7: "cycling",
      8: "dancing",
      9: "fitness",
      10: "football",
      11: "golf",
      12: "hiking",
      13: "hockey",
      14: "padel",
      15: "running",
      16: "sailing",
      17: "skateboard",
      18: "skiing",
      19: "snowboard",
      20: "soccer",
      21: "surfing",
      22: "swimming",
      23: "tennis",
      24: "volleyball",
      25: "yoga",
      26: "climbing",
      27: "crossfit",
      28: "other",
    },
    userName: "",
    uniqueId: "",
    chatHistory: [],
    sportsAreas: [],
    preferredLanguage: "en",
    isExistingUser: false,
    titleIsClicked: false,
    showGraph: null,
    myMSALObj: new PublicClientApplication({
      auth: {
        clientId: process.env.VUE_APP_CLIENT_ID,
        authority: process.env.VUE_APP_TENANT_ID,
        redirectUri: process.env.VUE_APP_REDIRECT_URI,
      },
      cache: {
        cacheLocation: "localStorage", // This ensures the authentication state is stored in localStorage
        storeAuthStateInCookie: true,
      },
    }),
  },
  getters: {
    myMSALObj: (state) => state.myMSALObj,
    getSportName: (state) => (id) => {
      return state.sportNames[id] || "Unknown Sport";
    },
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userName = userInfo.userName;
      state.uniqueId = userInfo.uniqueId;
    },
    setSelectedCategories(state, categories) {
      state.sportsAreas = categories["sportsAreas"];
    },
    setPreferredLanguage(state, language) {
      state.preferredLanguage = language["preferredLanguage"];
    },
    setShowGraph(state, showGraph) {
      state.showGraph = showGraph["showGraph"];
    },
    getChatHistory(state, chatHistory) {
      state.chatHistory = chatHistory["chatHistory"];
    },
    setIsExistingUser(state, isExistingUser) {
      state.isExistingUser = isExistingUser;
    },
    setTitleClicked(state, titleIsClicked) {
      state.titleIsClicked = titleIsClicked;
    },
    setMyMSALObject(state, myMSALObj) {
      state.myMSALObj = myMSALObj;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({ storage: window.localStorage })],
});
