<template>
  <div class="container">
    <transition name="fade">
      <div v-if="!userResponded && !okrSubmitted" class="streaming-div">
        <p class="objective-message">{{ $t("objectiveMessage") }}</p>
        <button
          @click="
            showOKRComponent = true;
            userResponded = true;
          "
          class="styled-button"
        >
          {{ $t("objectiveYes") }}
        </button>
        <button @click="userResponded = true" class="styled-button">
          {{ $t("objectiveNo") }}
        </button>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="showOKRComponent && !okrSubmitted" class="okr-component">
        <h2>{{ $t("addGoal") }}</h2>
        <div class="objective">
          <input
            type="text"
            v-model="objective"
            id="objective"
            :placeholder="$t('writeGoal')"
            maxlength="70"
          />
        </div>
        <div class="key-results">
          <label>{{ $t("keyResults") }}:</label>
          <div
            v-for="(keyResult, index) in keyResults"
            :key="index"
            class="key-result"
          >
            <input
              type="text"
              v-model="keyResults[index]"
              :placeholder="$t('writeKeyResult')"
              maxlength="70"
            />
            <span @click="removeKeyResult(index)" class="remove-sign"
              >&times;</span
            >
          </div>
          <button
            @click="addKeyResult"
            :disabled="keyResults.length >= 3"
            class="add-btn"
          >
            {{ $t("addResult") }}
          </button>
          <button
            @click="submitOKR"
            :disabled="isSubmitDisabled"
            class="submit-btn"
          >
            {{ $t("addObjective") }}
          </button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="okrSubmitted" class="okr-summary">
        <p>
          <strong>{{ $t("goal") }}:</strong> {{ objective }}
        </p>
        <div class="key-results-container">
          <div
            v-for="(keyResult, index) in keyResults"
            :key="index"
            class="key-result-item"
          >
            <input
              type="checkbox"
              v-model="checkedResults[index]"
              @change="checkAllKeyResults"
            />
            <label>{{ keyResult }}</label>
          </div>
        </div>
        <p>
          <strong>{{ $t("timeline") }}:</strong> {{ timeline }} ({{
            daysPassed
          }}
          {{ $t("daysPassed") }})
        </p>
        <div class="timeline">
          <div
            class="timeline-bar"
            :style="{ width: timelineWidth + '%' }"
          ></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    sportsArea: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOKRComponent: false,
      okrSubmitted: false,
      userResponded: false,
      objective: "",
      keyResults: [],
      checkedResults: [],
      timelineWidth: 0,
      startDate: null,
      endDate: null,
    };
  },
  created() {
    if (this.sportsArea.okr && this.sportsArea.okr[0].okrSubmitted) {
      //console.log("OKR data found in sportsArea");
      this.okrSubmitted = this.sportsArea.okr[0].okrSubmitted;
      this.objective = this.sportsArea.okr[0].objective;
      this.keyResults = this.sportsArea.okr[0].keyResults;
      this.checkedResults = this.sportsArea.okr[0].checkedResults;
      this.startDate = new Date(this.sportsArea.okr[0].startDate);
      this.endDate = new Date(this.sportsArea.okr[0].endDate);
      this.updateTimeline();
      // Set other variables from sportsArea.okr if needed
    } else {
      this.checkedResults = this.keyResults.map(() => false);
    }
  },
  computed: {
    isSubmitDisabled() {
      return !this.objective || this.keyResults.length === 0;
    },
    daysPassed() {
      const now = new Date();
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
      const passedDays = Math.ceil((now - start) / (1000 * 60 * 60 * 24));
      return `${passedDays} / ${totalDays}`;
    },
  },
  methods: {
    emitOKR() {
      let okr = [
        {
          okrSubmitted: this.okrSubmitted,
          objective: this.objective,
          keyResults: this.keyResults,
          checkedResults: this.checkedResults,
          startDate: this.startDate,
          endDate: this.endDate,
        },
      ];
      //console.log("Emitting OKR data:", okr); // Debugging log
      // Emit the okr object to the parent component
      this.$emit("update-okr", okr);
    },
    addKeyResult() {
      if (this.keyResults.length < 3) {
        this.keyResults.push("");
        this.checkedResults.push(false);
      }
    },
    removeKeyResult(index) {
      this.keyResults.splice(index, 1);
      this.checkedResults.splice(index, 1);
    },
    submitOKR() {
      this.okrSubmitted = true;
      this.startDate = new Date();
      this.endDate = new Date();
      this.endDate.setMonth(this.startDate.getMonth() + 6);
      this.updateTimeline();
      this.timelineInterval = setInterval(
        this.updateTimeline,
        1000 * 60 * 60 * 24
      ); // Update daily

      this.emitOKR();
    },
    updateTimeline() {
      if (this.startDate === null || this.endDate === null) {
        this.timelineWidth = 0;
        //console.log("Timeline width: ", this.timelineWidth); // Debugging log
      } else {
        //console.log("Updating timeline..."); // Debugging log
        const now = new Date();
        const totalDuration = this.endDate - this.startDate;
        const elapsedDuration = now - this.startDate;
        this.timelineWidth = Math.min(
          (elapsedDuration / totalDuration) * 100,
          100
        );
        console.log(`Timeline width: ${this.timelineWidth}%`); // Debugging log
        if (this.timelineWidth >= 100) {
          clearInterval(this.timelineInterval);
          alert(this.$t("sixMonthsPassed"));
          this.resetOKR();
        }
      }
    },
    checkAllKeyResults() {
      if (this.checkedResults.every((result) => result)) {
        if (confirm(this.$t("allCheckResults"))) {
          this.resetOKR();
        }
      } else {
        this.emitOKR();
      }
    },
    resetOKR() {
      this.showOKRComponent = false;
      this.okrSubmitted = false;
      this.userResponded = false;
      this.objective = "";
      this.keyResults = [];
      this.checkedResults = [];
      this.timelineWidth = 0;
      this.startDate = null;
      this.endDate = null;
      let okr = [
        {
          okrSubmitted: this.okrSubmitted,
          objective: this.objective,
          keyResults: this.keyResults,
          checkedResults: this.checkedResults,
          startDate: this.startDate,
          endDate: this.endDate,
        },
      ];
      this.$emit("update-okr", okr);
    },
  },
  beforeUnmount() {
    clearInterval(this.timelineInterval);
  },
};
</script>

<style scoped>
.container {
  position: relative;
  width: 100%;
}

.new-goal-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #59a471;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.new-goal-btn:hover {
  background-color: #76cd92;
}

.objective-message {
  margin-bottom: 8px;
  /* Adjust this value to decrease the space */
}

.styled-button {
  background-color: #59a471;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.styled-button:hover {
  background-color: #76cd92;
}

.okr-component,
.okr-summary,
.streaming-div {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}

.okr-component {
  max-width: 50%;
  margin: 10px auto;
  z-index: 2;
}

.streaming-div {
  z-index: 3;
}

.okr-summary {
  z-index: 1;
}

.okr-component h3 {
  margin-bottom: 20px;
  color: #333;
}

.key-results-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.key-result-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.objective input,
.key-results input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.key-results label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

.key-result {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.key-result input {
  flex: 1;
}

.remove-sign {
  cursor: pointer;
  color: red;
  margin-left: 9px;
  font-size: 1.5em;
  margin-bottom: 9px;
  /* Adjust the size as needed */
}

.add-btn,
.submit-btn {
  background-color: #76cd92;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.add-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.add-btn:hover,
.submit-btn:hover {
  opacity: 0.9;
}

h3 {
  margin-bottom: 16px;
  font-size: 1.5em;
}

.okr-summary {
  margin-top: 16px;
}

.okr-summary p {
  margin-bottom: 4px;
  /* Decrease the space below the goal paragraph */
}

.timeline {
  position: relative;
  width: 100%;
  height: 17px;
  background-color: #e0e0e0;
  border-radius: 10px;
  justify-content: center;
  margin-top: 5px;
}

.timeline-bar {
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(to right, #0b731d, #76cd92);
  transition: width 0.5s ease-in-out;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.streaming-div {
  animation: streaming 2s ease-in-out;
}

@keyframes streaming {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media (max-width: 599px) {
  .okr-component {
    max-width: 80%;
  }

  .objective-message {
    font-size: 14px;
  }

  .styled-button,
  .add-btn,
  .submit-btn {
    font-size: 14px;
    padding: 8px 16px;
  }

  .okr-summary {
    font-size: 14px;
  }

  h3 {
    font-size: 1.2em;
  }

  .submit-btn {
    margin-top: 12px;
  }

  .timeline {
    height: 12px;
  }
}
</style>
